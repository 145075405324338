<template>
  <div class="cert">
    <p class="bgText">Authorization by WGDO</p>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted() {
    if (!this.gIsLogin()) {
      this.$Modal.confirm({
        title: "提示",
        content: "登录后才能查询、下载证书",
        okText: "去登录",
        onOk: () => {
          this.$router.push("/login?fromUrl=/certificate");
        },
        onCancel: () => {
          this.$router.go(-1);
        }
      });
    }
  }
};
</script>


<style lang="scss" scoped>
.cert {
  position: relative;
  padding-bottom: 150px;
  background: #66bb22 url(/img/bgBottom.png) no-repeat bottom;
  background-size: contain;
  text-align: center;
  line-height: normal;
  margin-bottom: 50px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 30px;
    width: 150px;
    height: 179px;
    background: url(/img/logoLeft.png) no-repeat top center;
    background-size: contain;
  }
  &::after {
    content: "";
    position: absolute;
    top: 15px;
    right: 30px;
    width: 150px;
    height: 182px;
    background: url(/img/logoRight.png) no-repeat top center;
    background-size: contain;
  }

  .bgText {
    color: #97d672;
    font-size: 42px;
    font-weight: bold;
    padding: 30px 0;
  }
}
</style>